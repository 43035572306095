<template>
  <v-container class="fluid fill-height">
    <v-row class="text-center mb-12">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo-color.png')"
          class="my-3"
          contain
          height="80">
        </v-img>
      </v-col>

      <v-col class="mb-4">
        <span class="overline title mb-3">
          GV Hitelközpont
        </span>
        <br>
        <span class="display-1 font-weight-bold">
          Adminisztrációs felület
        </span>

        <p class="mt-2 font-weight-regular">
          <v-row>
            <v-col
              style="max-width:250px;"
              class="mx-auto">
              <v-row>
                <v-col
                  class="py-0 overline" style="text-transform: none;">
                  <span class="primary--text">{{$t('common.backend')}}</span> <span class="text-end">v{{$store.getters.appBackendVersion}}</span> <span class="primary--text">{{$t('common.frontend')}}</span> <span class="text-start">v{{ $store.getters.appFrontendVersion }}</span>
                </v-col>
              </v-row>
              <v-row v-show="false">
                <v-col class="pt-0 ml-2 caption text-start">
                  <ul>
                    <li>Lorem ipsum</li>
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Lorem ipsum dolor</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  style="max-width:100px;"
                  class="py-0 overline primary--text">
                  
                </v-col>
              </v-row>
              <v-row v-show="false">
                <v-col class="pt-0 ml-2 caption text-start">
                  <ul>
                    <li>Lorem ipsum</li>
                    <li>Lorem ipsum dolor sit amet</li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </p>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',
  }
</script>
