<template>
  <HelloWorld></HelloWorld>
</template>

<script>
  import DataComponentBase from '@/commons/DataComponentBase';
  import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'Home',
    extends: DataComponentBase,

    components: {
      HelloWorld,
    },
  }
</script>
